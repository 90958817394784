import React from "react";
import loaderImage from "/src/images/loader-boons-starter.gif";

export const Loader = () => {
  return (
    <div className="loader">
      <img src={loaderImage} alt="loader" />
    </div>
  );
};
